// @ts-nocheck
import RouteLink from 'next/link'
import React, { FC, useState } from 'react'

import { Card } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

import ProductContentCard from '~/components/cards/ProductCard/ProductContentCard'

import Paths from '~/utils/paths'

import { IS_RV } from '~/brands'
import { Listing } from '~/models/Listing/interface'

import SimpleCard from '../SimpleCard'

// import DealerBlock from './DealerBlock'

const useStyles = makeStyles(
  (_theme: Theme) =>
    createStyles({
      card: {
        position: 'relative',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        height: (props: { fullHeight: boolean }) =>
          props.fullHeight ? '100%' : 'auto',
        boxShadow: (props: { featured: boolean; fullHeight: boolean }) =>
          props.featured
            ? `-10px 30px 50px ${IS_RV ? '#098ACB' : '#27A4BF'} !important`
            : null,
        border: (props: { featured: boolean; fullHeight: boolean }) =>
          props.featured ? '2px solid #232323' : null,
        '& .MuiCardActionArea-root:hover .MuiCardActionArea-focusHighlight': {
          opacity: 0
        },
        '&:hover': {
          boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2) !important'
        }
      }
    }),
  { name: 'ProductGridCard' }
)

type Props = {
  data: Listing
  fullHeight?: boolean
  dealerBox?: boolean
  showDealerOnHover?: boolean
  loading?: boolean
  onClick?: () => void
  onFavorite?: (favorite: boolean) => void
  hasVariant?: (variant: SliderVariant) => void
  isReload?: boolean
  isShowSpecifications?: boolean
  featured?: boolean
}

const ProductGridCard: FC<Props> = ({
  data = {},
  fullHeight = true,
  featured,
  dealerBox,
  showDealerOnHover,
  loading,
  hasVariant,
  isShowSpecifications,
  ...rest
}) => {
  const classes = useStyles({ fullHeight, featured })
  const [showDealerBlock, setShowDealerBlock] = useState(false)
  const linkTarget = isShowSpecifications ? '_blank' : '_self'

  return (
    <>
      <Card
        component={SimpleCard}
        className={classes.card}
        style={{
          pointerEvents: loading ? 'none' : 'auto',
          // position: showDealerBlock ? 'absolute' : 'relative',
          height: showDealerBlock && `calc(100% + 62px)`,
          overflow: showDealerBlock ? `unset` : 'hidden',
          transition: 'all 0.3s'
        }}
        // onMouseEnter={() => {
        //   showDealerOnHover && setShowDealerBlock(true)
        // }}
        // onMouseLeave={() => {
        //   showDealerOnHover && setShowDealerBlock(false)
        // }}
      >
        <RouteLink
          href={Paths.details.buildDetails({ slug: String(data.slug) })}
          passHref
          prefetch={false}
        >
          <a
            target={linkTarget}
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
            <ProductContentCard
              data={data}
              isShowSpecifications={isShowSpecifications}
              loading={loading}
              {...rest}
            />
          </a>
        </RouteLink>
        {/* {(dealerBox || showDealerOnHover) && (
          <DealerBlock
            showDealerBlock={showDealerBlock}
            showDealerOnHover={showDealerOnHover}
            dealerBox={dealerBox}
            loading={loading}
            data={data}
          />
        )} */}
      </Card>
    </>
  )
}

export default ProductGridCard
