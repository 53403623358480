import React, { FC, useMemo } from 'react'

import { Box, CardActionArea, CardContent, Grid } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'

import Text from '~/components/shared/Text'

import { transformZeroToValue } from '~/utils/functions'

import { getSpecifications } from '~/brands'
import { Listing } from '~/models/Listing/interface'
import { ListingModel } from '~/models/Listing/model'

import ProductMedia from './ProductMedia'

const useStyles = makeStyles(
  (_theme: Theme) =>
    createStyles({
      cardAction: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      },
      price: {
        flexBasis: '100%',
        maxWidth: '100%'
      },
      cardContent: {
        flexGrow: 1,
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
      },
      apectRatio: {
        '--aspect-ratio': '16/9',
        paddingTop: 'calc((1 / (var(--aspect-ratio))) * 100%)',

        '@supports (aspect-ratio: 1)': {
          aspectRatio: 'var(--aspect-ratio)',
          paddingTop: 'initial'
        }
      }
    }),
  { name: 'ProductGridCard' }
)

const placeholder = new Array(5).fill({})

type Props = {
  data: Listing
  onFavorite?: (favorite: boolean) => void
  onRemoveFavorite?: (favorite: boolean) => void
  onClick?: () => void
  loading?: boolean
  isReload?: boolean
  isShowSpecifications?: boolean
}

const ProductContentCard: FC<Props> = ({
  data,
  onFavorite,
  onRemoveFavorite,
  loading,
  isReload,
  onClick,
  isShowSpecifications
}: Props) => {
  const [cover] = data?.images || []
  const classes = useStyles()

  const name = data?.short_title || ListingModel.createTitle(data)

  const specifications = useMemo(
    () => getSpecifications(data, 0.8).slice(0, 5),
    [data]
  )
  const coverUrl = cover?.url || data?.cover_url

  const specificationsData = loading ? placeholder : specifications

  return (
    <CardActionArea className={classes.cardAction} onClick={onClick}>
      <ProductMedia
        style={{ width: '100%' }}
        onFavorite={onFavorite}
        cover={coverUrl}
        onRemoveFavorite={onRemoveFavorite}
        favorite={data.favorite}
        featured={data.featured}
        loading={loading}
        isReload={isReload}
        productId={data.id}
        isPlus={data?.package_plus}
        isPremium={data?.package_premium}
        alt={name}
        className={classes.apectRatio}
        variant="grid"
      />
      <CardContent className={classes.cardContent}>
        <Text
          mt={0}
          mb={1}
          variant="h5"
          component="h2"
          fontFamily="Prompt, sans-serif"
          fontSize={18}
          fontWeight={400}
          style={{ minHeight: '55px' }}
          className="line-clamp-2"
        >
          {loading ? <Skeleton width={200} /> : name}
        </Text>

        <Grid container spacing={1} alignItems="center">
          <Grid item className={isShowSpecifications && classes.price} xs={6}>
            {loading ? (
              <Skeleton variant="text" width="100%" />
            ) : (
              <Text
                mt="auto"
                color="primary"
                variant="h5"
                fontSize={18}
                fontFamily="Prompt, sans-serif"
              >
                {transformZeroToValue(data.price)}
              </Text>
            )}
          </Grid>

          {!isShowSpecifications &&
            specificationsData.map(({ icon, value }, index) => (
              <Grid item xs={6} key={index}>
                {loading ? (
                  <Skeleton variant="text" width="100%" />
                ) : (
                  <Box display="flex" alignItems="center">
                    <Box
                      display="flex"
                      minWidth={32}
                      maxWidth={32}
                      justifyContent="center"
                      alignItems="center"
                    >
                      {icon}
                    </Box>

                    <Text noWrap fontSize={14} ml={1}>
                      {value}
                    </Text>
                  </Box>
                )}
              </Grid>
            ))}
        </Grid>
      </CardContent>
    </CardActionArea>
  )
}

export default ProductContentCard
