export class SlugModel {
  /**
   * Get id from slug
   *
   * @param slug slug
   */
  static getIdFromSlug(slug, separator = '_'): string {
    const [id] = slug.split(separator)
    return id
  }
}
