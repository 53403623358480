import { SlugModel } from '../Slug'

import { Listing } from './interface'

export class ListingModel extends SlugModel {
  /**
   * Generates location string
   *
   * @param address
   */
  static createLocation(address: any): string {
    const result =
      address?.address?.label ||
      address?.location?.name ||
      [address?.city, address?.state, address?.post_code]
        .filter(Boolean)
        .join(', ')

    return result
  }

  /**
   * Create listing title
   *
   * @param address
   */
  static createTitle(data: Pick<Listing, 'year' | 'make' | 'model'>): string {
    return [data?.year, data?.make?.name, data?.model?.name]
      .filter(Boolean)
      .join(' ')
  }
}
